import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify/:id/:clave', element: <VerifyCode /> },
        { path: 'reset/:Token', element: (
        <GuestGuard>
          <ResetPasswordTwo />
        </GuestGuard>)}
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <RoleBasedGuard accessibleRoles={['admin', 'user']}> <GeneralBooking /></RoleBasedGuard>},
        // { path: 'booking', element: <GeneralBooking /> },
        { path: 'funcionario', element: <Funcionarios /> },
        {
          path: 'plandecompras',
          children: [
            { element: <Navigate to="/dashboard/plandecompras/enter/new" replace />, index: true },
            { path: 'list', element: <MisPlanComprasAdmin /> },
            { path: 'enter/new', element: <PlanCompras /> },
            { path: 'enter/new/edit/:id', element: <PlanCompras /> },
            { path: 'enter/ingreso', element: <PresupuestoIng /> },
            { path: 'list/:id', element: <PlanView />}
          ],
        },

        {
          path: 'document',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <BibliotecaDocumental /> },
            { path: 'memo', element: <BibliotecaDocumental /> },
            { path: 'document/:id', element: <DocumentView /> },
            { path: 'memo/new', element: <DocumentalMemoCreate /> },
            { path: 'memo/:name/edit', element: <DocumentalMemoCreate /> },
            { path: 'decreto', element: <CrearDecreto /> },
          ],
        },
        {
          path: 'documental',
          children: [
            { element: <Navigate to="/dashboard/documental/shop" replace />, index: true },
            { path: 'shop', element: <RoleBasedGuard accessibleRoles={['admin', 'user']}> <BibliotecaDocumental /> </RoleBasedGuard> },
            { path: 'repositorio', element: <RoleBasedGuard accessibleRoles={['user','Bibliotecario']}> <Repositorio/> </RoleBasedGuard> },
            { path: 'document/:id', element: <DocumentView /> },
            { path: 'documents/:id', element: <DocumentDeptoView /> },
            { path: 'memo', element: <DocumentalMemo /> },
            { path: 'memoRepo', element: <DocumentalMemoRepo /> },
            { path: 'decre', element: <DocumentalDec /> },
            { path: 'decreRepo', element: <DocumentalDec /> },
            { path: 'depto', element: <DocumentalDep /> },
            { path: 'product/new', element: <DocumentalMemoCreate /> },
            { path: 'product/:name/edit', element: <DocumentalMemoCreate /> },
          ],
        },
        {
          path: 'contracting',
          children: [
            { element: <Navigate to="/dashboard/contracting/enter/new" replace />, index: true },
            { path: 'list', element: <MisContrataciones /> },
            { path: 'enter/new', element: <Contratacion /> },
            { path: 'list/:id', element: <ContratacionView />}
          ],
        },
        {
          path: 'contractingAdmin',
          children: [
            { element: <Navigate to="/dashboard/contractingAdmin/enter/admin" replace />, index: true },
            // { path: 'list', element: <MisContrataciones /> },
            { path: 'enter/admin', element: <MisContratacionesAdmin /> },
            { path: 'list/:id', element: <ContratacionView />}
          ],
        },
        {
          path: 'presupuesto',
          children: [
            { element: <Navigate to="/dashboard/presupuesto/enter/new" replace />, index: true },
            { path: 'list', element: <MisPresupuesto /> },
            { path: 'enter/new', element: <Presupuesto /> },
            { path: 'enter/new/edit/:id', element: <Presupuesto /> },
            { path: 'enter/ingreso', element: <PresupuestoIng /> },
            { path: 'list/:id', element: <PresupuestoView />}
          ],
        },
        {
          path: 'presupuestoAdmin',
          children: [
            { element: <Navigate to="/dashboard/presupuestoAdmin/enter/admin" replace />, index: true },
            { path: 'list', element: <MisItems /> },
            { path:'programas', element: <MisProgramas />},
            { path: 'enter/admin', element: <MisPresupuestosAdmin /> },
            { path: 'list/:id', element: <PresupuestoView />}
          ],
        },
        {
          path: 'gas',
          children: [
            { element: <Navigate to="/dashboard/gas/enter/new" replace />, index: true },
            { path: 'list', element: <MisGasSolicitudes /> },
            { path: 'enter/new', element: <Gas /> },
            { path: 'list/:id', element: <ContratacionView />}
          ],
        },
        {
          path: 'gasAdmin',
          children: [
            { element: <Navigate to="/dashboard/gasAdmin/enter/admin" replace />, index: true },
            { path: 'list', element: <MisGasSolicitudes /> },
            { path: 'enter/admin', element: <GasAdmin /> },
            { path: 'list/:id', element: <ContratacionView />}
          ],
        },
        {
          path: 'administrativos',
          children: [
            { element: <Navigate to="/dashboard/administrativos/list" replace />, index: true },
            { path: 'list', element: <Administrativos /> },
            { path: 'enter/new', element: <Gas /> },
            { path: 'list/:id', element: <ContratacionView />}
          ],
        },
        {
          path: 'marcaciones',
          children: [
            { element: <Navigate to="/dashboard/marcaciones/propias" replace />, index: true },
            { path: 'propias', element: <Marcaciones /> },
          ],
        },
        {
          path: 'asistencia',
          children: [
            { element: <Navigate to="/dashboard/asistencia/ingresar/nueva" replace />, index: true },
            { path: 'list', element: <AsistList /> },
            { path: 'ingresar/nueva', element: <Toner /> },
            // { path: 'list/:id', element: <ContratacionView />}
          ],
        },
        {
          path: 'ticket',
          children: [
            { element: <Navigate to="/dashboard/ticket/ingresar/nuevo" replace />, index: true },
            { path: 'list', element: <TIcketList /> },
            { path: 'ingresar/nuevo', element: <Ayuda /> },
            // { path: 'list/:id', element: <ContratacionView />}
          ],
        },
        {
          path: 'tonerAdmin',
          children: [
            { element: <Navigate to="/dashboard/tonerAdmin/enter/admin" replace />, index: true },
            { path: 'enter/admin', element: <TonerAdmin /> },
          ],
        },
        {
          path: 'emergencias',
          children: [
            { element: <Navigate to="/dashboard/emergencias/ingresar/nuevo" replace />, index: true },
            { path: 'list', element: <MisEmergenciaSolicitudes /> },
            { path: 'ingresar/nuevo', element: <Emergencias /> },
            { path: 'list/:id', element: <EmergenciaView />}
          ],
        },
        // {
        //   path:'contracting',
        //   childen: [
        //     { element: <Navigate to="/dashboard/contracting/shop" replace />, index: true },
        //     { path: 'shop', element: <EcommerceShop /> },
        //     // { element: <Navigate to="/dashboard/contracting/enter" replace />, index: true },
        //     // { path: 'enter/new', element:<EcommerceShop />}
        //   ]
        // },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> }, 
            { path: 'subrogancia', element: <SubroganciaPropia /> }, 
          ],
        },
        {
          path: 'subrogancias',
          children: [
            { element: <Navigate to="/dashboard/subrogancias/municipal" replace />, index: true },
            { path: 'municipal', element: <Subrogancia /> },
            { path: 'salud', element: <Subrogancia /> },
            { path: 'educacion', element: <Subrogancia /> }           
          ],
        },
        {
          path: 'mantenedorsubrogancias',
          children: [
            { element: <Navigate to="/dashboard/mantenedorsubrogancias/subrogancia" replace />, index: true },
            { path: 'subrogancia', element: <MantenedoresSubrogancia /> },
            { path: 'salud', element: <MantenedoresSubrogancia /> },
            { path: 'educacion', element: <MantenedoresSubrogancia /> },            
          ],
        },
        {
          path: 'mantenedordepartamentos',
          children: [
            { element: <Navigate to="/dashboard/mantenedordepartamentos/departamento" replace />, index: true },
            { path: 'departamento', element: <MantenedoresDepartamento /> },
            { path: 'salud', element: <MantenedoresDepartamento /> },
            { path: 'educacion', element: <MantenedoresDepartamento /> }
          ],
        },
        {
          path: 'mantenedorusuario',
          children: [
            { element: <Navigate to="/dashboard/mantenedorusuario/usuario" replace />, index: true },
            { path: 'usuario', element: <MantenedoresUsuario /> }            
          ],
        },
        {
          path: 'mantenedorvehiculo',
          children: [
            { element: <Navigate to="/dashboard/mantenedorvehiculo/vehiculo" replace />, index: true },
            { path: 'vehiculo', element: <MantenedoresVehiculo /> }            
          ],
        },
        {
          path: 'mantenedorimpresora',
          children: [
            { element: <Navigate to="/dashboard/mantenedorimpresora/impresoras" replace />, index: true },
            { path: 'impresoras', element: <MantenedoresImpresoras /> }            
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'decreto',
          children: [
            { element: <Navigate to="/dashboard/decreto/inbox" replace />, index: true },
            { path: 'document/:id', element: <DocumentView />},
            { path: 'label/:customLabel', element: <Decreto /> },
            { path: 'label/:customLabel/:mailId', element: <Decreto /> },
            { path: ':systemLabel', element: <Decreto /> },
            { path: ':systemLabel/:mailId', element: <Decreto /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'vehiculos', element: <Vehiculos />},
        { path: 'tramitador', element: <Tramitador />},
        { path: 'bodega',
          children: [
            { element: <Navigate to="/dashboard/bodega/list" replace />, index: true },
            { path: 'list', element: <Bodega /> },            
            { path: 'form', element: <FormularioBodega /> }, 
            { path: 'list/:id', element: <BodegaView />}
          ]
        },
        { path: 'kanban', element: <Kanban /> },
        { path: 'kanbanStats', element: <KanbanStats /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <GuestGuard>
                <Login />
              </GuestGuard>,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const ResetPasswordTwo = Loadable(lazy(() => import('../pages/auth/ResetPasswordTwo')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const BibliotecaDocumental = Loadable(lazy(() => import('../pages/dashboard/BibliotecaDocumental')));
const DocumentalMemo = Loadable(lazy(() => import('../pages/dashboard/VistadeDocs')));
const DocumentalMemoRepo = Loadable(lazy(() => import('../pages/dashboard/VistadeDocsRepo')));
const DocumentalDec = Loadable(lazy(() => import('../pages/dashboard/VistadeDocsDec')));
const DocumentalDep = Loadable(lazy(() => import('../pages/dashboard/VistaporDepto')));
const DocumentalMemoCreate = Loadable(lazy(() => import('../pages/dashboard/DocumentalMemoCreate')));
const CrearDecreto = Loadable(lazy(() => import('../pages/dashboard/CrearDecreto')));
 
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

const Subrogancia = Loadable(lazy(() => import('../pages/dashboard/Subrogancia')));
const SubroganciaPropia = Loadable(lazy(() => import('../pages/dashboard/SubroganciaPropias')));

const MantenedoresSubrogancia = Loadable(lazy(() => import('../pages/maintainers/MantenedoresSubrogancia')));
const MantenedoresDepartamento = Loadable(lazy(() => import('../pages/maintainers/MantenedoresDepartamento')));
const MantenedoresUsuario = Loadable(lazy(() => import('../pages/maintainers/MantenedoresUsuario')));
const MantenedoresVehiculo = Loadable(lazy(() => import('../pages/maintainers/MantenedoresVehiculo')));
const MantenedoresImpresoras = Loadable(lazy(() => import('../pages/maintainers/MantenedoresImpresoras')));


const DocumentView = Loadable(lazy(() => import('../pages/dashboard/DocumentView')));
const Repositorio = Loadable(lazy(() => import('../pages/dashboard/Repositorio')));
const Funcionarios = Loadable(lazy(() => import('../pages/dashboard/Funcionarios')));

const DocumentDeptoView = Loadable(lazy(() => import('../pages/dashboard/VistadeDocsDepto')));
 
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Decreto = Loadable(lazy(() => import('../pages/dashboard/Decreto')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/AgendaVehiculo')));
const KanbanStats = Loadable(lazy(() => import('../pages/dashboard/KanbanStats')));

const Vehiculos = Loadable(lazy(() => import('../pages/dashboard/Vehiculos')));
// bodega
const Bodega = Loadable(lazy(() => import('../pages/dashboard/Bodega')));
const FormularioBodega = Loadable(lazy(() => import('../pages/dashboard/FormularioBodega')));
const BodegaView = Loadable(lazy(() => import('../pages/dashboard/BodegaView')));

// 
const Tramitador = Loadable(lazy(() => import('../pages/dashboard/DocDig')));


const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Contrataciones
const Contratacion = Loadable(lazy(() => import('../pages/dashboard/AprobacionContratacion')));
const MisContrataciones = Loadable(lazy(() => import('../pages/dashboard/MisContrataciones')));
const ContratacionView = Loadable(lazy(() => import('../pages/dashboard/ContratacionView')));
const MisContratacionesAdmin = Loadable(lazy(() => import('../pages/dashboard/MisContratacionesAdmin')));
// AyudaTicket

const Ayuda = Loadable(lazy(() => import('../pages/dashboard/AyudaMuni')));


// Presupuesto
const Presupuesto = Loadable(lazy(() => import('../pages/dashboard/FormularioPresupuesto')));
const PresupuestoIng = Loadable(lazy(() => import('../pages/dashboard/FormularioPresupuestoIng')));
const MisPresupuesto = Loadable(lazy(() => import('../pages/dashboard/MisPresupuestoSoli')));
const PresupuestoView = Loadable(lazy(() => import('../pages/dashboard/PresupuestoView')));
const MisPresupuestosAdmin = Loadable(lazy(() => import('../pages/dashboard/MisPresupSoliAdmin')));
const MisItems = Loadable(lazy(() => import('../pages/dashboard/MisItems')));
const MisProgramas = Loadable(lazy(() => import('../pages/dashboard/MisProgramas')));


// Plan de compras
const PlanCompras = Loadable(lazy(() => import('../pages/dashboard/FormularioPlanCompras')));
const MisPlanComprasAdmin = Loadable(lazy(() => import('../pages/dashboard/MisPlanCompraAdmin')));
const PlanView =  Loadable(lazy(() => import('../pages/dashboard/PlanComprasView')));

// Gas
const Gas = Loadable(lazy(() => import('../pages/dashboard/FormularioGas')));
const MisGasSolicitudes = Loadable(lazy(() => import('../pages/dashboard/MisGasSoli')));
// Gas Admin
const GasAdmin = Loadable(lazy(() => import('../pages/dashboard/MisGasSoliAdmin')))
// Permisos administrativos
const Administrativos = Loadable(lazy(() => import('../pages/dashboard/MisAdministrSol')))
// Marcaciones
const Marcaciones = Loadable(lazy(() => import('../pages/dashboard/Marcaciones')));

// Asistencia
const Toner = Loadable(lazy(() => import('../pages/dashboard/FormularioToner')));
const AsistList = Loadable(lazy(() => import('../pages/dashboard/MisTonerSoli')));
const TonerAdmin = Loadable(lazy(() => import('../pages/dashboard/MisTonerSoliAdmin')))

const TIcketList = Loadable(lazy(() => import('../pages/dashboard/MisAsistSoli')));



// Emergencias
const Emergencias = Loadable(lazy(() => import('../pages/dashboard/FormularioEmergencias')));
const MisEmergenciaSolicitudes = Loadable(lazy(() => import('../pages/dashboard/MisEmergenciaSoli'))); 
const EmergenciaView = Loadable(lazy(() => import('../pages/dashboard/EmergenciaView')));



// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
